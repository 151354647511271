<template>
    <section class="mx-3 mt-2" style="border-radius:12px 12px 0px 0px;">
        <div class="row mx-0 align-items-center px-3 py-2">
            <div class="bg-general3 cr-pointer rounded-circle d-middle-center cr-pointer" style="width:20px;height:20px;" @click="irAtras">
                <i class="icon-left text-white f-18" />
            </div>
            <div class="col-auto text-general f-18 f-600">
                Importar archivo de inventario (CEDIS: {{ cedis.nombre }})
            </div>
            <div class="bg-general2 text-white br-8 px-3 py-1 f-14 cr-pointer ml-auto mr-2" @click="$refs.modalCancelarSubida.toggle()">
                Cancelar
            </div>
            <div class="bg-general text-white br-8 px-3 py-1 f-14 cr-pointer" @click="modalConfirmar">
                Importar Productos
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 111px);">
            <div class="row mx-0 align-items-center mt-3 py-3 bg-white">
                <div class="col-8">
                    <div class="row mx-0">
                        <div class="bg-general2 text-white br-5 px-3 py-1 f-15 mr-1 ml-3 cr-pointer" @click="$refs.modalImportarStock.editar(detalle.origen)">
                            Reemplazar archivo
                        </div>
                        <p class="text-general f-15 px-3 my-auto">Esta importación esta basada en el <b>{{ detalle.origen == 1 ? 'BarCode' : 'SKU' }}</b> de los productos.</p>
                    </div>
                    <div class="row mx-0 px-3 py-3">
                        <i class="icon-ok-circled f-22 text-success" />
                        <i>{{ detalle.productos_numero }} / {{ dataTable.length }} Registros correctamente cargados</i>
                        <i class="icon-comision f-18 text-general ml-4 mr-2" />
                        <i>{{ separadorNumero(detalle.productos_valor) }} Valor del inventario</i>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row mx-0 px-3 py-2">
                        <el-radio v-model="radio" label="1" class="text-general f-15">
                            Actualizar las cantidades
                        </el-radio>
                    </div>
                    <div class="row mx-0 px-3 py-2">
                        <el-radio v-model="radio" label="2" class="text-general f-15">
                            Reemplazar las cantidades
                        </el-radio>
                    </div>
                </div>
            </div>
            <div class="row mx-0 align-items-center mt-3 py-4 bg-white px-4 bg-light-f5">
                <div class="col-auto text-general f-18 f-600">
                    <p> Productos importados</p>
                </div>
                <div class="col-auto ml-auto px-2">
                    <el-tooltip placement="bottom" content="Cambiar el proveedor para todos los registros" effect="light">
                        <div class="bg-white br-8 shadow d-middle-center cr-pointer" style="width:32px;height:32px;" @click="cambiarProveedor()">
                            <i class="icon-proveedor text-general f-18" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="col-auto px-2">
                    <el-tooltip placement="bottom" content="Cambiar la observación para todos los registros" effect="light">
                        <div class="bg-white br-8 shadow d-middle-center cr-pointer" style="width:32px;height:32px;" @click="cambiarObservacion()">
                            <i class="icon-playlist-edit text-general f-18" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <div class="row mx-0 mt-2">
                <div class="col-12">
                    <tabla-general
                    :mostrar-buscador="false"
                    :data="dataTable"
                    class-header="text-general f-16"
                    alto="calc(100vh - 381px)"
                    >
                        <template slot="adicionales-izquierda">
                            <el-table-column width="80" class-name="text-center">
                                <template slot-scope="{row}">
                                    <el-tooltip v-if="!row.visible" placement="bottom" effect="light">
                                        <div slot="content">
                                            Este producto no esta habilitado<br /> o no se vende en este CEDIS
                                        </div>
                                        <i class="icon-ojo-cerrado f-20 text-general-red" />
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                            <el-table-column :label="detalle.origen == 1 ? 'BarCode' : 'SKU'" width="180">
                                <template slot-scope="{row}">
                                    <p class="text-general f-14">
                                        {{ row.barcode }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column label="Producto" width="200">
                                <template slot-scope="{row}">
                                    <el-tooltip v-if="row.id_producto === null" placement="bottom" content="No se encontró el producto" effect="light">
                                        <i class="icon-attention-alt text-general-red f-20" />
                                    </el-tooltip>
                                    <p v-else class="text-general f-14">
                                        {{ row.nombre_producto }} - {{ row.presentacion }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column label="Cantidad Actual" min-width="140" align="center">
                                <template slot-scope="{row}">
                                    <p class="text-general f-14">
                                        {{ redondearNumero(row.stock_disponible) }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column label="Cantidad a subir" min-width="150" align="center">
                                <template slot-scope="{row}">
                                    <p v-if="validarFormato(row.cantidad)" class="text-general f-14">
                                        {{ row.cantidad }}
                                    </p>
                                    <el-tooltip v-else placement="bottom" content="Cantidad Invalida" effect="light">
                                        <i class="icon-attention-alt text-general-red f-20" />
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                            <el-table-column label="Valor compra" min-width="150" align="center">
                                <template slot-scope="{row}">
                                    <p v-if="validarFormato(row.valor)" class="text-general f-14">
                                        {{ separadorNumero(row.valor) }}
                                    </p>
                                    <el-tooltip v-else placement="bottom" content="Valor Invalido" effect="light">
                                        <i class="icon-attention-alt text-general-red f-20" />
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                            <el-table-column label="Proveedor" width="180">
                                <template slot-scope="{row}">
                                    <el-tooltip v-if="row.id_proveedor === null" placement="bottom" content="No se encontró proveedor, se permite importar el producto" effect="light">
                                        <i class="icon-alert-triangle text-warning f-20" />
                                    </el-tooltip>
                                    <p v-else class="text-general f-14">
                                        {{ row.proveedor }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column label="Observación" width="180">
                                <template slot-scope="{row}">
                                    <el-tooltip placement="bottom" :content="row.comentario" effect="light">
                                        <p class="text-general f-14 nombre">
                                            {{ row.comentario }}
                                        </p>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                            <el-table-column width="50">
                                <template slot-scope="{row}">
                                    <i class="icon-pencil-outline f-20 text-general cr-pointer" @click="editarRegistro(row)" />
                                </template>
                            </el-table-column>
                            <el-table-column width="50">
                                <template slot-scope="{row}">
                                    <i class="icon-delete-outline f-20 text-general cr-pointer" @click="modalEliminarRegistro(row.id)" />
                                </template>
                            </el-table-column>
                        </template>
                    </tabla-general>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-editar-datos ref="modalEditarRegistroTemp" @update="listar" />

        <modal ref="modalCambiarProveedor" titulo="Cambiar Proveedor" tamano="modal-md" no-aceptar adicional="Actualizar" @adicional="updateProveedorGeneral">
            <div class="row mx-0 px-5 py-3">
                <div class="col-12">
                    <p class="pl-3 text-general f-14">Proveedor</p>
                    <el-select v-model="id_proveedor_general" size="small" clearable class="w-100">
                        <el-option
                        v-for="item in proveedores"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
        </modal>

        <modal ref="modalCambiarObservacion" titulo="Cambiar observacion" tamano="modal-md" no-aceptar adicional="Actualizar" @adicional="updateComentarioGeneral">
            <ValidationObserver ref="validacion" tag="div" class="row mx-0 px-5 py-3">
                <ValidationProvider v-slot="{ errors }" rules="required|max:250" tag="div" class="col-12">
                    <p class="pl-3 text-general f-14">Observación de la compra</p>
                    <el-input v-model="comentario_general" type="textarea" :rows="5" show-word-limit class="w-100" maxlength="250" />
                    <p class="text-general-red f-12">{{ errors[0] }}</p>
                </ValidationProvider>
            </ValidationObserver>
        </modal>

        <modal-eliminar ref="modalEliminarRegistro" titulo="Eliminar registro" mensaje="¿Desea eliminar este registro?" @eliminar="eliminarRegistro" />

        <modal ref="modalConfirmarImportacion" titulo="Confirmar Subida" tamano="modal-md" no-aceptar adicional="Importar" @adicional="realizarImportacion">
            <div class="row mx-0 px-5 py-3">
                <div class="col-12">
                    <p class=" text-general text-center f-14">Esta a punto de cargar el stock a {{ detalle.productos_numero }} Productos</p>
                    <p class="text-general text-center f-14 mt-2">¿Está seguro de realizar esta acción?</p>
                </div>
            </div>
        </modal>
        <modalSiNo
        ref="modalCancelarSubida"
        titulo="Cancelar Subida"
        mensaje="¿Desea descartar el archivo a importar?"
        icon="close"
        @accion="descartarImportacion"
        />
        <modal-subir-stock ref="modalImportarStock" @update="listar" />
    </section>
</template>

<script>
import Productos from "~/services/productos/productos_stocks";
import CedisProductos from '~/services/cedis/cedis_productos'
export default {
    beforeRouteEnter(to, from, next){
        next(vm => {
            // Obtenemos ruta anterior y parametros de la ruta
            vm.ruta_anterior_path = from.path;
            vm.ruta_anterior_name = from.name;
            if (vm.ruta_anterior_name === 'admin.cedis.ordenes-compra.detalle'){
                vm.id_oc = from.params.id_oc;
            }
        });
    },
    components: {
        modalEditarDatos: () => import('./partials/modalEditarRegistroTemp'),
        modalSubirStock: () => import('./partials/modalImportarStock')
    },
    data(){
        return {
            options: [{
                value: 'Option1',
                label: 'Option1'
            }],
            value: '',
            observacion: '',

            dataTable: [],
            detalle:{},
            id_eliminar:null,
            proveedores:[],
            id_proveedor_general:null,
            comentario_general:null,
            id_oc: '',
            ruta_anterior_path: '',
            ruta_anterior_name: '',
            radio: '1'
        }
    },
    computed:{
        id_cedis(){
            return Number(this.$route.params.id_cedis)
        },
        id_importacion(){
            return Number(this.$route.params.id_importacion)
        },
        cedis(){
            if(this.cedis_calculo.length == 0){
                return {}
            }
            return this.cedis_calculo.find(o=>o.id == this.id_cedis)
        }
    },
    mounted(){
        this.listar()
    },
    methods: {
        async listar(){
            try {
                let params={
                    id_importacion:this.id_importacion,
                    id_cedis:this.id_cedis
                }
                const {data} = await Productos.getImportacion(params)
                this.dataTable = data.registros
                this.detalle = data.importacion

            } catch (e){
                this.error_catch(e)
            }
        },
        validarFormato(value){
            if(!isNaN(value) && value >= 0) return true
            else return false
        },
        async realizarImportacion(){
            try {
                if(this.detalle.productos_numero === 0) return
                let params = {
                    tipo:this.radio
                }

                const {data} = await Productos.realizarImportacion(this.id_importacion, params)
                this.notificacion('Éxito','Archivo importado correctamente','success')
                this.$refs.modalConfirmarImportacion.toggle()
                this.$router.push({name:'admin.cedis.historial-importaciones',params:{id_cedis:this.id_cedis}})

            } catch (e){
                this.error_catch(e)
            }
        },
        editarRegistro(item){
            this.$refs.modalEditarRegistroTemp.toggle(item, this.detalle.origen);
        },
        modalEliminarRegistro(id){
            this.id_eliminar = id
            this.$refs.modalEliminarRegistro.toggle();
        },
        async eliminarRegistro(){
            try {
                const {data} = await Productos.deleteRegistro(this.id_eliminar)
                this.id_eliminar = null
                this.notificacion('Éxito','Registro eliminado','success')
                this.listar()
                this.$refs.modalEliminarRegistro.toggle();

            } catch (e){
                this.error_catch(e)
            }
        },
        async listaProveedores(){
            try {
                const id_cedis = this.id_cedis

                const {data} = await CedisProductos.listaProveedores({id_cedis})
                this.proveedores = data.proveedores
            } catch (e){
                this.error_catch(e)
            }
        },
        async updateProveedorGeneral(){
            try {
                if(this.id_proveedor_general === null) return
                let form = {
                    id_proveedor: this.id_proveedor_general,
                    id_importacion: this.id_importacion
                }
                const {data} = await Productos.updateProveedorGeneral(form)
                this.id_proveedor_general = null
                this.notificacion('Éxito','Se actualizó el proveedor a todos los productos','success')
                this.listar()
                this.$refs.modalCambiarProveedor.toggle();

            } catch (e){
                this.error_catch(e)
            }
        },
        async updateComentarioGeneral(){
            try {
                if(this.comentario_general === null || this.comentario_general === '') return
                let form = {
                    comentario: this.comentario_general,
                    id_importacion: this.id_importacion
                }
                const {data} = await Productos.updateComentarioGeneral(form)
                this.comentario_general = null
                this.notificacion('Éxito','Se actualizó comentario general','success')
                this.listar()
                this.$refs.modalCambiarObservacion.toggle();

            } catch (e){
                this.error_catch(e)
            }
        },
        cambiarProveedor(){
            if(this.proveedores.length == 0){
                this.listaProveedores()
            }
            this.$refs.modalCambiarProveedor.toggle();
        },
        cambiarObservacion(){
            this.$refs.modalCambiarObservacion.toggle();
        },
        modalConfirmar(){
            this.$refs.modalConfirmarImportacion.toggle()
        },
        async descartarImportacion(res){
            try {
                if(!res){
                    this.$refs.modalCancelarSubida.toggle()
                    return
                }
                const {data} = await Productos.descartarImportacion(this.id_importacion)
                this.notificacion('Éxito','Importacion Cancelada','success')
                this.$refs.modalCancelarSubida.toggle();
                this.$router.push({name:'admin.cedis.productos'})

            } catch (e){
                this.error_catch(e)
            }
        },
        subirArchivo(){
            this.$refs.modalImportarStock.toggle();
        },
        irAtras(){
            if (this.ruta_anterior_name === 'admin.cedis.ordenes-compra.detalle'){
                return this.$router.push({name: 'admin.cedis.ordenes-compra.detalle', params: {id_oc: this.id_oc, id_cedis: this.id_cedis}});
            }
            this.$router.push({name:'admin.cedis.productos'})
        }

    }
}
</script>
<style lang="scss" scoped>
/* .upload-demo{
    display: flex;
    ul{
        display: flex;
    }
} */
</style>
